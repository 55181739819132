import styled from 'styled-components'
import { FormControl, MenuItem, InputLabel } from '@mui/material'
import Select from '@mui/material/Select'
import { useTranslation } from 'react-i18next'
import { TextBody1Medium } from '../Styles/TextCustom'
import { themeColors } from '../../const/colors'
import { UserFilter } from '../../screens/Users/UserListScreen'

const UserFilterSelect = ({
  value,
  setValue,
}: {
  value: UserFilter | ''
  setValue: (v: UserFilter | '') => void
}) => {
  const { t } = useTranslation(['user_search'])
  const data = [
    {
      name: UserFilter.login,
    },
    {
      name: UserFilter.lastName,
    },
    {
      name: UserFilter.email,
    },
    {
      name: UserFilter.workEmail,
    },
    {
      name: UserFilter.tenant,
    },
    {
      name: UserFilter.carPlate,
    },
    {
      name: UserFilter.cardNumber,
    },
  ]

  const handleChange = (event: any) =>
    setValue(event.target.value as UserFilter)

  return (
    <FormControl>
      <InputLabel id="select-label">{t('choose_filter')}</InputLabel>
      <SelectStyled
        labelId="select-label"
        value={value}
        label={t('choose_filter')}
        onChange={handleChange}
        data-e2e="user-filter-select"
      >
        {data.map(({ name }, i) => (
          <MenuItem key={i} value={name}>
            <Text>{t(name)}</Text>
          </MenuItem>
        ))}
      </SelectStyled>
    </FormControl>
  )
}

export default UserFilterSelect

const SelectStyled = styled(Select)`
  background-color: ${themeColors.white};
  width: 200px;
`
const Text = styled(TextBody1Medium)`
  margin: 0;
`
