import { gql } from '@apollo/client'
import { IQueryBasicData } from '../shared/IQueryData'

export interface IStatParkingPoolDailyUsageVars {
  from: string
  to: string
  tenant: string
  parkingName: string
}

export interface IStatParkingPoolDailyUsageData {
  statParkingPoolDailyUsage: IQueryBasicData & {
    parameters: {
      name: string
      value: string
      type: string
    }[]
    stats: {
      date: string
      parkingName: string
      tenant: string
      sold: number
      used: number
      capacity: number
    }[]
  }
}

export const STAT_PARKING_POOL_DAILY_USAGE = gql`
  query statParkingPoolDailyUsage(
    $from: DateTime!
    $to: DateTime!
    $tenant: String!
    $parkingName: String!
  ) {
    statParkingPoolDailyUsage(
      from: $from
      to: $to
      tenant: $tenant
      parking_name: $parkingName
    ) {
      status
      code
      error
      message
      stats {
        date
        parkingName: parking_name
        tenant: tenant_name
        sold
        used
        capacity
      }
    }
  }
`
