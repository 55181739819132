import gql from 'graphql-tag'

export interface IQueryTenantData {
  queryTenant: {
    id: string
    name: string
    slug: string
  }[]
}

export const QUERY_TENANT = gql`
  query queryTenant {
    queryTenant(order: { asc: name }) {
      id
      name
      slug
    }
  }
`
