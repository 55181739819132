import gql from 'graphql-tag'
import { IQueryBasicData } from '../shared/IQueryData'

export interface IParkingManagementDailyUsageStatVars {
  from: string
  to: string
  tenant: string
  parkingName: string
}

export interface IStats {
  date: string
  parking: string
  sold: number
  tenant?: string
}

export interface IParkingManagementDailyUsageStatData {
  parkingDailyUsage: IQueryBasicData & {
    stats: IStats[]
  }
  parkingPoolDailyUsage: IQueryBasicData & {
    stats: IStats[]
  }
}

export const PARKING_MANAGEMENT_DAILY_USAGE_STAT = gql`
  query parkingManagementDailyUsageStat(
    $from: DateTime!
    $to: DateTime!
    $tenant: String!
    $parkingName: String!
  ) {
    parkingDailyUsage: statParkingSubscriptionsDailyUsage(
      from: $from
      to: $to
      skip_pools: true
    ) {
      status
      message
      error
      code
      stats {
        date
        parking: parking_name
        sold
      }
    }
    parkingPoolDailyUsage: statParkingPoolDailyUsage(
      from: $from
      to: $to
      tenant: $tenant
      parking_name: $parkingName
    ) {
      status
      code
      error
      message
      stats {
        date
        parking: parking_name
        tenant: tenant_name
        sold
      }
    }
  }
`
