import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  useReactTable,
  ColumnFiltersState,
  FilterFn,
  SortingState,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  ColumnDef,
  flexRender,
  // TableRowProps,
  // TableCellProps,
  ColumnFilter,
} from '@tanstack/react-table'
import {
  Table as MuiTable,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  Checkbox,
  TableContainer,
  TablePagination,
  Grid,
} from '@mui/material'
import GlobalFilterInput from '../components/Shared/GlobalFilterInput'
import { useTranslation } from 'react-i18next'
import TableCSVLink from '../components/Table/TableCSVLink'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons'
import { themeColors } from '../const/colors'
import { RankingInfo, rankItem } from '@tanstack/match-sorter-utils'
import { TextBody1 } from '../components/Styles/TextCustom'
import ColumnFilterInput from './Table/ColumnFilter'
import { useAppSelector } from '../redux/store'

declare module '@tanstack/react-table' {
  //add fuzzy filter to the filterFns
  interface FilterFns {
    fuzzy: FilterFn<unknown>
  }
  interface FilterMeta {
    itemRank: RankingInfo
  }
}

//Define a custom fuzzy filter function that will apply ranking info to rows (using match-sorter utils)
const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value)
  // Store the itemRank info
  addMeta({
    itemRank,
  })
  // Return if the item should be filtered in/out
  return itemRank.passed
}

interface TableProps<TData> {
  columns: ColumnDef<TData>[]
  data: TData[]
  pagination?: boolean
  columnVisibility?: any
  enableRowSelection?: boolean
  onSelectionChange?: (data: TData[]) => void
  csvExport?: boolean
  csvHeaders?: { label: string; key: string }[] | string[]
  csvFileName?: string
  filterCsvValue?: string
  filterCsvKey?: string
  onRowClick?: (rowData: TData) => void
  sortByKey?: string
  sortDesc?: boolean
  getCellProps?: (cell: any) => any | void
  getRowProps?: (rowData: TData) => any | void
  children?: React.ReactNode
  colFiltersOn?: boolean
  defaultColumnFilter?: ColumnFilter[]
  manualPagination?: boolean
  pageIndex?: number
  pageSize?: number
  setPageSize?: (v: number) => void
  count?: number
  setCurrentPage?: (v: number) => void
  manualSorting?: boolean
  globalSearch?: boolean
  manualFiltering?: boolean
  enableColumnFilters?: boolean
}

const defaultPropGetter = (cell: any) => ({})
const defaultRowPropGetter = (row: any) => ({})

const Table = <TData extends object>({
  columns,
  data,
  pagination = true,
  columnVisibility,
  enableRowSelection,
  onSelectionChange,
  csvExport,
  csvHeaders,
  csvFileName,
  filterCsvValue,
  filterCsvKey,
  onRowClick,
  sortByKey = '',
  sortDesc = false,
  colFiltersOn = false,
  getCellProps = defaultPropGetter,
  getRowProps = defaultRowPropGetter,
  defaultColumnFilter,
  children,
  manualPagination = false,
  pageSize = 20,
  pageIndex,
  setPageSize,
  count,
  setCurrentPage,
  manualSorting = false,
  globalSearch = true,
  manualFiltering = false,
  enableColumnFilters = true,
}: TableProps<TData>) => {
  const { t } = useTranslation('table_component')
  const [customColumns, setCustomColumns] = useState<ColumnDef<TData>[]>([])
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    [],
  )
  const [globalFilter, setGlobalFilter] = useState('')
  const [rowSelection, setRowSelection] = useState({})
  const [selectedRows, setSelectedRows] = useState<TData[]>([])
  const [sorting, setSorting] = React.useState<SortingState>([])
  const { features } = useAppSelector((state) => state.user)

  useEffect(() => {
    if (enableRowSelection) {
      setCustomColumns([
        {
          id: 'select',
          header: ({ table }) => (
            <Checkbox
              {...{
                checked: table.getIsAllRowsSelected(),
                indeterminate: table.getIsSomeRowsSelected(),
                onChange: table.getToggleAllRowsSelectedHandler(),
              }}
            />
          ),
          cell: ({ row }) => (
            <Checkbox
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
              onClick={(e) => e.stopPropagation()}
            />
          ),
        },
        ...columns,
      ])
    } else setCustomColumns(columns)
  }, [enableRowSelection, columns])

  useEffect(() => {
    if (defaultColumnFilter) {
      setColumnFilters(defaultColumnFilter)
    }
  }, [defaultColumnFilter])

  useEffect(() => {
    if (sortByKey) {
      setSorting([{ id: sortByKey, desc: sortDesc }])
    }
  }, [])

  const table = useReactTable({
    data,
    columns: customColumns,
    manualPagination,
    manualSorting,
    manualFiltering,
    enableColumnFilters,
    filterFns: {
      fuzzy: fuzzyFilter, //define as a filter function that can be used in column definitions
    },
    initialState: {
      pagination: {
        pageSize,
      },
    },
    state: {
      columnVisibility,
      columnFilters,
      globalFilter,
      rowSelection,
      sorting,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: 'auto', //apply fuzzy filter to the global filter (most common use case for fuzzy filter)
    enableRowSelection,
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(), //client side filtering
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  })

  useEffect(() => {
    setSelectedRows(
      table.getSelectedRowModel().rows.map((item) => item.original),
    )
  }, [rowSelection, table])

  useEffect(() => {
    if (onSelectionChange) {
      onSelectionChange(selectedRows)
    }
  }, [selectedRows, onSelectionChange])

  return (
    <>
      <Container container justifyContent="space-between" alignItems="center">
        <Grid item>
          {globalSearch && (
            <GlobalFilterInput
              filter={globalFilter}
              setFilter={setGlobalFilter}
            />
          )}
        </Grid>
        <Grid item>
          {csvExport && (
            <TableCSVLink
              csvHeaders={csvHeaders}
              fileName={csvFileName}
              data={selectedRows}
              filterCsvValue={filterCsvValue}
              filterCsvKey={filterCsvKey}
            />
          )}
        </Grid>
      </Container>
      {children}
      <TableContainer sx={{ maxHeight: '78vh' }}>
        <TableStyled stickyHeader>
          <TableHead>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <HeaderStyled
                      key={header.id}
                      colSpan={header.colSpan}
                      $isClickable={header.column.getCanSort()}
                    >
                      {header.isPlaceholder ? null : (
                        <Column
                          $short={header.column.id === 'select'}
                          $searchable={
                            header.column.getCanFilter() &&
                            features?.includes('admin_filtered_columns')
                          }
                        >
                          <TitleRow
                            {...{
                              className: header.column.getCanSort()
                                ? 'cursor-pointer select-none'
                                : '',
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                            {{
                              asc: (
                                <ArrowContainer>
                                  <FontAwesomeIcon
                                    icon={faArrowUp as IconProp}
                                    color={themeColors.primary}
                                  />
                                </ArrowContainer>
                              ),
                              desc: (
                                <ArrowContainer>
                                  <FontAwesomeIcon
                                    icon={faArrowDown as IconProp}
                                    color={themeColors.primary}
                                  />
                                </ArrowContainer>
                              ),
                            }[header.column.getIsSorted() as string] ?? null}
                          </TitleRow>
                          {header.column.getCanFilter() &&
                          !!features?.includes('admin_filtered_columns') ? (
                            <Row>
                              <ColumnFilterInput column={header.column} />
                            </Row>
                          ) : null}
                        </Column>
                      )}
                    </HeaderStyled>
                  )
                })}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {table.getRowModel().rows.map((row) => {
              return (
                <TableRowStyled
                  key={row.id}
                  {...getRowProps(row.original)}
                  selected={row.getIsSelected()}
                  $isClickable={!!onRowClick}
                  onClick={() => onRowClick && onRowClick(row.original)}
                >
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <Cell key={cell.id} {...getCellProps(cell.getContext())}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </Cell>
                    )
                  })}
                </TableRowStyled>
              )
            })}
            {!data.length && (
              <TableRowStyled>
                <NoItemsCell>
                  <NoItemsText color={themeColors.lightGray}>
                    {t('no_items')}
                  </NoItemsText>
                </NoItemsCell>
              </TableRowStyled>
            )}
          </TableBody>

          <TableFooter>
            {table.getFooterGroups().map((footerGroup) => (
              <TableRow key={footerGroup.id}>
                {footerGroup.headers.map((footer) => (
                  <CellStyled key={footer.id}>
                    {flexRender(
                      footer.column.columnDef.footer,
                      footer.getContext(),
                    )}
                  </CellStyled>
                ))}
              </TableRow>
            ))}
          </TableFooter>
        </TableStyled>
      </TableContainer>

      {pagination && (
        <TablePagination
          component="div"
          count={count || table.getPrePaginationRowModel().rows.length}
          page={table.getState().pagination.pageIndex}
          onPageChange={(_, page) => {
            table.setPageIndex(page)
            setCurrentPage && setCurrentPage(page)
          }}
          rowsPerPageOptions={[10, 20, 30, 50, 100]}
          rowsPerPage={table.getState().pagination.pageSize}
          onRowsPerPageChange={(e) => {
            const size = e.target.value ? Number(e.target.value) : 10
            table.setPageSize(size)
            setPageSize && setPageSize(size)
          }}
          showFirstButton
          showLastButton
        />
      )}
    </>
  )
}

export default Table

const TableRowStyled = styled(TableRow)<{ $isClickable?: boolean }>`
  ${({ $isClickable }) => $isClickable && 'cursor: pointer;'}
`
const TableStyled = styled(MuiTable)`
  overflow-x: scroll;
`
const HeaderStyled = styled(TableCell)<{ $isClickable?: boolean }>`
  ${({ $isClickable }) => $isClickable && 'cursor: pointer;'}
  padding: 5px 10px;
  background-color: ${themeColors.lighterBlue};
  color: ${themeColors.primary};
`
const CellStyled = styled(TableCell)`
  font-size: 16px;
  font-weight: 600;
`
const ArrowContainer = styled.div`
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
`
const Container = styled(Grid)`
  padding: 16px 0;
`
const Column = styled.div<{ $short?: boolean; $searchable?: boolean }>`
  ${({ $searchable }) => $searchable && 'min-height: 90px;'}
  min-width: 100px;
  ${({ $short }) => $short && 'min-width: 50px;'}
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
`
const TitleRow = styled(Row)`
  flex: 1;
  align-items: end;
`
const Cell = styled(TableCell)`
  padding: 10px;
`
const NoItemsText = styled(TextBody1)`
  text-align: center;
`
const NoItemsCell = styled(Cell)`
  width: 100px;
`
