import gql from 'graphql-tag'
import { IReservation } from '../shared/sharedTypes'

export enum ParkingPlaceStatus {
  available = 'available',
  unavailable = 'unavailable',
  reserved = 'reserved',
  occupied = 'occupied',
}
export interface IPlace {
  id: string
  name: string
  status: ParkingPlaceStatus
  parking: {
    name: string
  }
  level: {
    name: string
  }
  building: {
    name: string
  }
  tenant: {
    id: string
    name: string
  }
  info: {
    name: string
    message: string
  }
  assignedTo: {
    login: string
    firstName: string
    lastName: string
  }
  shared: boolean
  reservations: IReservation[]
  logs: {
    createdAt: string
    message: string
  }[]
}

export interface IQueryParkingPlaceReservationsVars {
  status?: ParkingPlaceStatus
}

export interface IQueryParkingPlaceReservationsData {
  places: IPlace[]
}
export const QUERY_PARKING_PLACE_RESERVATIONS = gql`
  query queryParkingPlace {
    places: queryParkingPlace(filter: { status: { eq: available } }) {
      id
      name
      status
      parking {
        name
      }
      level {
        name
      }
      building {
        name
      }
      tenant {
        id
        name
      }
      info {
        name
        message
      }
      assignedTo: assigned_to {
        login
        firstName: first_name
        lastName: last_name
      }
      shared
      reservations(order: { desc: created_at }) {
        validFrom: valid_from
        validTo: valid_to
        status
      }
    }
  }
`
