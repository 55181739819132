const en = {
  generic: {
    error: 'Error',
    ups: 'Oops!',
    understand: 'Understand',
    no_options: 'No options',
    network_error: 'Network error',
    qraphql_error: 'GraphQL error',
    no: 'No',
    maybe_later: 'Maybe later',
    exit_without_saving:
      'Are you sure you want to leave without saving changes?',
    free: 'Free',
    yes: 'Yes',
    active: 'Active',
    inactive: 'Inactive',
    visible: 'Visible',
    invisible: 'Invisible',
    available: 'Available',
    unavailable: 'Unavailable',
    success_message: 'Changes have been saved',
    selected: 'selected',
    no_results: 'No results',
    reservation_created: 'Reservation has been created',
    pl: 'Polish',
    en: 'English',
    all: 'All',
    none: 'None',
    no_parameters: 'Required parameters for login are missing.',
  },
  btn: {
    log_in: 'Log in',
    go_to_app: 'Go to application',
    create_new_password: 'Create new password',
    save: 'Save',
    confirm: 'Confirm',
    cancel: 'Cancel',
    accept: 'Accept',
    reject: 'Reject',
    add_tenant: 'Add tenant',
    save_tenant: 'Save tenant',
    add_domain: 'Add domain',
    delete_domain: 'Delete domain',
    delete: 'Delete',
    delete_selected: 'Delete selected',
    send_message: 'Send message',
    send_again: 'Send selected again',
    exit: 'Exit',
    add_menu_item: 'Add new dish',
    more_data: 'More Data',
    edit: 'Edit',
    add_dish: 'Add dish',
    edit_dish: 'Edit dish',
    add_subscriptions_parking: 'Add subscription',
    search: 'Search',
    filter_clear: 'Clear filter',
    clear_filters: 'Clear filters',
    expand: 'Expand',
    collapse: 'Collapse',
    back_to_user: 'go back to User Details',
    add_survey: 'Add Survey',
    show_regulation: 'Show regulations',
    hide_regulation: 'Hide regulation',
    add_comment: 'Add comment',
    submit_range: 'Submit date range',
    save_changes: 'Save changes',
    logout: 'Logout',
    download_csv: 'Download CSV',
    show: 'Show',
    send: 'Send',
    close_without_message: 'Close without reply',
    sign_up: 'Sign up',
    send_link_again: 'Send link again',
    go_to_mysolvy: 'Go to MySolvy',
    send_link: 'Send link',
    refresh: 'Refresh',
    on: 'On',
    off: 'Off',
    submit_filters: 'Submit filters',
    assign: 'Assign',
  },
  form: {
    password: 'Password',
    new_password: 'New password',
    email_login: 'Email or Login',
    field_required: 'This field is required',
    select_tenant: 'Select a tenant...',
    tenant_required: 'You must select a tenant to accept',
    no_tenants: 'No tenants',
    comment: 'Optional comments for the user',
    company_name: 'Company name',
    domain_name: 'Email domain name',
    login: 'Login',
    contact_email: 'Contact Email',
    work_email: 'Work Email',
    first_name: 'First Name',
    last_name: 'Last Name',
    search: 'Search',
    send_again: 'Resend selected',
    message: 'Write a reply',
    form_error: 'Fill in required fields',
    log_comment: 'Comment',
    valid_nip: 'Enter valid NIP',
    feature_enabled: 'Feature enabled',
    feature_disabled: 'Feature disabled',
    amano_product_id: 'Amano product id',
    places_amount: 'Places amount',
    max_simultaneous_users: 'Max simultaneous users',
    max_continuous_days: 'Max continuous days',
    max_advanced_days: 'Max advanced days',
    max_simultaneous_reservations: 'Max simultaneous reservations',
    shared_parking_feature: 'Shared places feature',
    places_count: 'Number of seats to be reserved',
    from: 'From',
    to: 'To',
    correct_form: 'Correct incorrectly filled fields',
    email_msg:
      'An activation link will be sent to the e-mail address provided. If it does not arrive - contact us: aplikacja@oliviacentre.com. If you provide your work email, you will get resident status needed to book Olivia Garden ticket faster.',
    login_msg:
      'Why login? So that - regardless of the change of job, name or email - always have access to all application services',
    password_msg: 'Minimum 6 characters',
    private_or_work_email: 'Private or work email',
    check_all: 'Check all',
    required_fields: 'Required fields',
    phone: 'Phone number',
    valid_phone: 'Type valid phone number',
    email_or_login: 'Email or login',
    profile_name: 'Profile name',
  },
  days: {
    Monday: 'Mo',
    Tuesday: 'Tu',
    Wednesday: 'We',
    Thursday: 'Th',
    Friday: 'Fr',
    Saturday: 'Sa',
    Sunday: 'Su',
  },
  admin_menu: {
    garden: 'Garden',
    garden_tickets: 'Garden tickets',
    gastro: 'Restaurant',
    gastro_menu: 'Menu',
    orders: 'Orders',
    orders_history: 'Order history',
    parking_areas: 'Parking areas',
    parking_sold_subscription_list: 'Sold subscriptions list',
    payment_history: 'Payment history',
    parking_subscription_product_list: 'Subscriptions products list',
    parking_sold_one_time_tickets: 'Sold one time parking ticket list',
    parking_list: 'Parking list',
    parking_places: 'Parking place statuses',
    parking_place_reservations: 'Parking place reservations',
    users: 'Users',
    tenant_issues: 'Tenant status requests',
    users_list: 'Users list',
    unconfirmed_emails: 'Unconfirmed emails',
    companies_list: 'Companies',
    messages: 'Messages',
    minimize_menu: 'Minimize Menu',
    surveys: 'Surveys',
    surveys_list: 'Surveys List',
    surveys_results_list: 'Surveys Results ',
    blocking_olivka_products: 'Blocking Olivka products',
    bike_lockers: 'Bike lockers',
    lockers_list: 'Locker list',
    product_list: 'Product list',
    group_reservation_list: 'Group reservation list',
    reservation_list: 'Reservation list',
    locker_statistics: 'Statistics',
    parking_occupancy_report: 'Parking occupancy report',
    parking_pool_occupancy_report: 'Tenant parking occupancy report',
    parking_management_report: 'Management report',
    tenant_parking_places: 'Tenant parking places',
    getresponse_sync: 'Synchronization',
    email_list: 'User list',
    integrations: 'Integrations',
    events: 'Events',
    event_tickets: 'Ticket list',
    event_sales_system: 'Sales system',
    delete_account_requests: 'Delete account requests',
    event_reports: 'Reports',
    tenant_parking: 'Tenant parking reservations',
    current_version: 'Current version of the application',
    new_push: 'New push',
    push_list: 'Push list',
    payout: 'Payout',
    banner_in_app: 'Banner in app',
    user_roles_list: 'User roles list',
    car_plate_verification_report: 'Inspection report',
  },
  menu_item_form: {
    none: 'None',
    vege: 'Vege',
    vegan: 'Vegan',
    fixed_menu: 'Fixed menu',
    select_date: 'Select date',
    name_and_price: 'Name and price',
    polish_version: 'Polish version',
    english_version: 'English version',
    dish_category: 'Dish category',
    diet: 'Diet',
    availability: 'Availability',
    edit_title: 'Edit dish',
    add_title: 'Add dish',
    dish_name: 'Dish name',
    description: 'Description (optional)',
    price: 'Price',
    amount: 'Amount',
    unit: 'Unit',
    no_units: 'No units',
    no_category: 'No category',
    confirm_title: 'Are you sure you want to save the changes?',
    success_edit: 'Changes have been saved',
    success_add: 'Changes have been saved',
    dish_deleted: 'Changes have been saved',
    delete_confirm_title: 'Do you really want to delete?',
    all_weekdays: 'All weekdays',
  },
  login_screen: {
    my: 'My',
    remind_password: "I don't remember my password",
  },
  email_verification_page: {
    success: 'Your account is now active',
    tip: 'You can now log into the application',
    new_email_title: 'Your new email address has been confirmed',
    new_email: 'You can now log in with your new email',
  },
  recover_password_page: {
    title: 'Create a new password for your account',
    success:
      'Password was changed correctly, you can now log into the application.',
  },
  remind_password_screen: {
    info: 'Enter your login or email address, to which we will send you a link to log in to the application.',
  },
  remind_password_email_sent_screen: {
    header: "We've sent the link to your email address",
    info: 'Open the link we sent you to your email address to change your account password.',
    info2:
      'Mail did not come? Check the SPAM folder. You can resend the link if necessary.',
    success: 'Link has been sent again',
  },
  tenant_request_details: {
    title: 'Request for resident status',
    full_name: 'Name:',
    message: 'Message:',
    login: 'Login:',
    contact_email: 'Contact email:',
    company_email: 'Company email:',
    accepted: 'Accepted',
    rejected: 'Rejected',
    reply: 'Reply:',
    close_at: 'Closure date:',
    resolved_by: 'Verified by:',
  },
  user_message_details: {
    title: 'Message details',
    from: 'From:',
    email: 'Email:',
    company: 'Company:',
    date: 'Date:',
    message: 'Message:',
    success: 'Message was sent correctly',
    reply: 'Reply:',
    closeAt: 'Closure date:',
    resolved_by: 'Replied by:',
    delete_account: 'Account deletion request',
    os: 'System:',
    app: 'App version:',
    api: 'API version:',
    backend: 'Backend version:',
    application_problem: 'Application problem',
    account_delete_request: 'Account delete request',
    issue_type: 'Issue type',
    change_issue_type: 'Change issue type:',
    user_details: 'User details',
  },
  tenant: {
    add_new_tenant: 'Add new tenant',
    edit_tenant: 'Edit tenant',
    tenant_domains: 'Tenant domains:',
    add_domain: 'Add domain:',
    confirm_title: 'Are you sure you want to edit this tenant?',
    add_tenant_success: 'New tenant was added',
    add_domain_success: 'Domain was created',
    seos_card: 'SEOS card',
    add_seos_card: 'Add SEOS card',
    parking_pool_profiles: 'Parking pool profiles:',
    add_profile: 'Add profile',
    edit_profile: 'Edit profile',
    active: 'Active',
    short_name: 'Short name',
    name: 'Name',
    mysolvy_companies: 'MySolvy company list',
    assigned_mysolvy_company: 'Assigned MySolvy company',
    ms_company_assigned: 'MySolvy company has been linked',
    ms_company_unassigned:
      'The association with MySolvy company has been removed',
    myolivia_company: 'MyOlivia company',
  },
  email_verification_requests: {
    send_again: 'Send again',
    success: 'Email with verification code has been sent again',
    delete_request: 'Are you sure you want to delete this verification code?',
    delete_selected_requests:
      'Are you sure you want to delete selected verification codes?',
  },
  error: {
    no_app: 'Application not found.',
  },
  code: {
    100: 'OK',
    101: 'Email verified',
    102: 'Profile changed',
    103: 'Password changed',
    104: 'Tenant status updated',
    105: 'Email confirmed',
    106: 'User logged in',
    107: 'User registered',
    108: 'Change email address request accepted. Follow instructions from mail message.',
    109: 'Tenant status accepted',
    110: 'Tenant status rejected',
    111: 'Acceptance status changed',
    200: 'Ticket ordered',
    201: 'Ticket found',
    202: 'Ticket cancelled',
    300: 'Issue submitted',
    301: 'Issue resolved',
    400: 'Click verification link sent by email',
    998: 'Reserved - do not use',

    999: 'Error 999 occurred, please try again later',
    1000: 'Bad email address',
    1001: 'Error 1001 has occurred, please try again later',
    1002: 'Bad login or password',
    1003: 'Email not verified',
    1004: 'Error 1004 has occurred, please try again later',
    1005: 'Cannot verify email',
    1006: 'Invalid token, please login again',
    1007: 'Error 1007 occurred, please try again later',
    1008: 'Error 1008 occurred, please try again later',
    1009: 'Cannot add user',
    1010: 'Cannot verify user',
    1011: 'Date in wrong format',
    1012: 'Internal error - cannot get user info',
    1013: 'Cannot confirm tenant status',
    1014: 'Cannot update user',
    1015: 'Cannot update tenant status',
    1016: 'Cannot confirm email',
    1017: 'Login already taken',
    1018: 'Email address already registered',
    1019: 'Missing parameter: email',
    1020: 'Missing parameter: verification_code',
    1021: 'Missing parameter: password',
    1022: 'Cannot change password',
    1023: 'Unsupported email type',
    1024: 'Internal error - cannot parse template',
    1025: 'Invalid/expired verification code',
    1026: 'Bad parameter - action',
    1027: 'Unfortunately, the e-mail address provided was not found on our resident list - select option below.',
    1028: 'Insufficient privileges',
    1029: 'Cannot assign role',
    1030: 'Cannot unassign role',
    1031: 'Cannot add tenant',
    1032: 'Cannot modify tenant',
    1033: 'Cannot delete tenant',
    1034: 'Cannot create domain',
    1035: 'Cannot modify domain',
    1036: 'Cannot delete domain',
    1037: 'Cannot assign domain',
    1038: 'Cannot unassign domain',
    1039: 'Bad service name',
    1040: 'Cannot send email',
    1041: "We don't have such an account in our database",
    1042: 'Login too short',
    1043: 'Bad character in login',
    1044: 'Cannot resend verification code - email not known or code expired',
    1045: 'Parameter value not supported',
    1046: 'Cannot delete user',
    1047: 'User has no building assigned',
    1048: 'User has no access card assigned',
    1049: 'Wrong access card type',
    1050: 'User not found',
    1051: 'Wrong access card number',
    1100: 'Required acceptances not provided',
    2000: 'No active ticket',
    2001: 'No active ticket',
    2002: 'Ticket server internal error',
    2003: 'Cannot buy ticket for past date',
    2004: 'Already have active ticket',
    2005: 'Internal error - unknown parameter for the ticket server',
    2006: 'Ticket server internal error',
    2007: 'You were close. There is no free slot for the date selected by you.',
    2008: 'Cannot order ticket',
    2009: 'Date out of range',
    2010: 'Oops ... You cannot book your ticket more than 31 days in advance.',
    2011: 'Ticket already used',
    3000: 'Unsupported issue type',
    3001: 'Unable to submit your request, please try again later',
    3002: 'Cannot get issue',
    7001: 'Unsupported status',
    7003: 'User already has locker ordered',
    7004: 'Already cancelled',
    7005: 'No active locker order found',
    10025: 'Order not found',
    10026: 'Content processing error',
    10027: 'No invoice',
    10028: 'Payment not found',
    12001: 'User logged in',
    12002: 'User has no sufficient permissions/roles',
  },
  user_details: {
    title: 'User details',
    confirm_modal_title: 'Confirm changes',
    firstName: 'First Name',
    lastName: 'Last Name',
    company: 'Company',
    user_roles: 'User roles',
    no_roles: 'No user roles',
    choose_role: 'Choose user roles',
    unconfirmed_user: '* Unconfirmed user',
    tenant_change_success: 'Tenant was assigned',
    tenant_deleted_success: 'Assigned tenant has been deleted',
    add_role: 'Role was assigned',
    delete_role: 'Role was unassigned',
    assigned_restaurants: 'Assigned restaurants',
    no_restaurants: 'No restaurants',
    choose_restaurant: 'Choose restaurant',
    add_restaurant: 'Restaurant was assigned',
    delete_restaurant: 'Restaurant was unassigned',
    switch_lock_enable: 'User unlocked',
    switch_lock_disabled: 'User blocked',
    lock: 'User lock',
    lock_success: 'User has been blocked',
    unlock_success: 'User has been unblocked',
    parking_no_plate: 'No registration numbers',
    parking_placeHolder: 'Cars plate ',
    user_parking: 'User cars plates ',
    acceptances: 'User acceptances:',
    global_searcher: 'Search more information about user:',
    add_feature: 'Feature was assigned',
    delete_feature: 'Feature was unassigned',
    user_features: 'User features',
    no_features: 'No features',
    choose_feature: 'Choose feature',
    access_card: 'Access card',
    access_card_change_success: 'Access card assigned',
    add_role_confirmation1: 'Are you sure you want to assign role ',
    add_role_confirmation2: ' to a user?',
    remove_role_confirmation1: 'Are you sure you want to delete ',
    remove_role_confirmation2: ' role?',
    change_role_confirmation: 'Are you sure you want to ',
    remove_role: 'delete role: ',
    give_role: 'assign role: ',
    anonymize: 'Anonymize user',
    anonymized: 'Anonymized user',
    anonymize_success: 'User has been anonymized',
    anonymize_info: 'Only a blocked user can be anonymized.',
    anonymize_status: 'Anonymization status: ',
    anonymized_date: 'Anonymization date: ',
    anonymize_confirmation: 'Are you sure you want to anonymize user ',
    block_user_confirmation: 'Are you sure you want to block user ',
    restore_user: 'Restore user',
    role: 'Role',
    remove_user_role: 'Remove role from selected users',
    remove_role_hint: 'Select the role and then the users from the list',
    remove_role_success: 'Role has been removed correctly',
    block_user: 'Block user',
    unblock_user: 'Unblock user',
    expand_role_legend: 'Expand role descriptions',
    collapse_role_legend: 'Collapse role descriptions',
    expand_acceptances: 'Expand acceptances',
    collapse_acceptances: 'Collapse acceptances',
    change_history: 'Change history',
  },
  user_search: {
    garden_tickets: 'Tickets to Garden',
    user_acceptance_history: 'Show users consent history',
    parking_subcriptions: 'Parking subscriptions / tickets',
    locker_reservations: 'Locker reservation',
    user_messages: 'Messages',
    user_messages_closed: 'Closed messages',
    no_questions: 'No inquiries',
    get_query: 'Choose your query',
    last_name: 'Last name',
    work_email: 'Work email',
    company: 'Company',
    choose_filter: 'Choose filter',
    filter_title: 'Find user:',
    login: 'Login',
    lastName: 'Last name',
    email: 'Email',
    workEmail: 'Work email',
    tenant: 'Company',
    carPlate: 'Car plate',
    cardNumber: 'Card number',
  },

  table_component: {
    download_csv: 'Download CSV',
    title: 'Advanced filtering by column',
    choose_column: 'Choose a column',
    choose_column_no: 'No column in table',
    value: 'Value',
    enter_value: 'Enter a search term',
    no_items: 'No data',
    search: 'Search...',
  },
  columns: {
    full_name: 'Full name',
    login: 'Login',
    main_email: 'Main email',
    work_email: 'Work email',
    unconfirmed_email: 'Unconfirmed email',
    created_at: 'Send date',
    company: 'Company',
    tenant: 'Tenant',
    domains: 'Email domains',
    roles: 'Roles',
    message: 'Message content',
    message_date: 'Message date',
    close_date: 'Closure date',
    status: 'Status',
    visit_date: 'Visit date',
    qr_code: 'QR Code',
    reply: 'Reply',
    creation_date: 'Creation date',
    dish_name: 'Dish name',
    price: 'Price',
    availability: 'Available',
    visible: 'Visible',
    diet_type: 'Diet type',
    category: 'Category',
    last_mobile_app: 'Last logged from mobile app',
    last_seen: 'Last login date',
    api_version: 'API version',
    car_plate: 'Car Plates',
    features: 'Features',
    ticket_type: 'Ticket type',
    order_id: 'Order Id',
    payment_status: 'Payment status',
    parking_pool: 'Parking pool',
    resident: 'Resident',
    account_status: 'Account status',
    card_type: 'Card type',
    title: 'Title',
    account_confirmed: 'Account confirmed',
    account_created: 'Account creation date',
    mysolvy_company: 'MySolvy company',
  },
  account_status: {
    active: 'Active',
    disabled: 'Disabled',
    anonimized: 'Anonymised',
  },
  tabs: {
    new: 'New',
    closed: 'Closed',
    pending: 'Pending',
    verified: 'Verified',
    app: 'From app',
    other: 'Others',
  },
  diet_types: {
    vege: 'Vege',
    vegan: 'Vegan',
    none: '',
  },
  categories: {
    breakfest: 'Breakfast',
    drinks: 'Drinks',
    dinners: 'Dinner sets',
    soups: 'Soups',
    extras: 'Extras',
    dumplings: 'Dumplings',
    pizzas: 'Pizzas',
    sandwiches: 'Sandwiches',
    salads: 'Salads',
    desserts: 'Desserts',
    market: 'Market',
    self_made: 'Self Made',
    sushi: 'Sushi',
    pastas: 'Pastas',
    cakes: 'Ciasta',
    cofee: 'Coffee',
    buns: 'Buns',
  },

  gastro: {
    invisible_restaurant: 'Restaurant is not visible in the application',
    available_tip:
      'Dish is visible in application according to availability settings',
  },

  garden_ticket_status: {
    new: 'Waiting for payment',
    reserved: 'Reserved',
    active: 'Active',
    active_outside: 'Active outside',
    cancelled: 'Cancelled',
    past: 'Past',
    past_overridden: 'Past overridden',
    unused: 'Unused',
    unavailable: 'Unavailable',
    error: 'Error',
    company: '[non Data]',
  },
  garden_order_status: {
    new: 'Waiting for payment',
    received: 'Waiting for payment',
    processing: 'Waiting for payment',
    confirmed: 'Waiting for payment',
    completed: 'Paid',
    failed: 'Payment cancelled',
    error: 'Error',
  },
  non_data: {
    non_data: '[non Data]',
  },
  user_details_table: {
    createdAt: 'Date of change',
    service: 'What kind of service',
    title: 'Consent title',
    required: 'Is it required?',
    current: 'Current user choice',
    content: 'The content of the consent',
    buttonBack: 'Close and back to User List',
    topic: 'User consent history:',
  },
  user_acceptances: {
    required: 'Is consent required?:',
    selected: 'Has the user selected consent?:',
    service: 'Which service is the consent for:',
    current: 'Is the consent / regulations currently in force:',
    createdAt: 'First Decision Date:',
    modifiedAt: 'Date of the last decision:',
  },
  bool: {
    yes: 'Yes',
    no: 'No',
  },
  payment_history: {
    details_title: 'Payment details',
    payment_date: 'Payment date',
    ticket_id: 'Ticket id',
    ticket_type: 'Ticket type',
    ticket_start: 'Ticket from',
    ticket_stop: 'Ticket to',
    product_name: 'Name of product',
    seller_company: 'Seller',
    seller_nip: 'Seller NIP',
    invoice: 'Invoice',
    invoice_data: 'Invoice data',
    parking_name: 'Parking name',
    fv: 'Faktura Vat',
    payment_id: 'Payment ID',
    account_firstname: 'First Name',
    account_lastname: 'Last Name',
    email: 'Email',
    nip: 'Nip',
    name: 'Company name',
    zipcode: 'Zipcode',
    street: 'Street',
    city: 'City',
    country: 'Country',
    car_plate_1: 'Car plate',
    car_plate_2: 'Car plate',
    access_card_id: 'OBC card ID',
    tenant_name: 'Tenant',
    payment_value_gross: 'Payment value gross',
    payment_history: 'Payment history',
    payment_status: 'Payment status',
    subscription: 'Subscription',
    oneTimeTicket: 'One time ticket',
    ticket_number: 'Ticket number',
    dates: 'Dates',
    car_plates: 'Car plates',
    price: 'Price',
    created_at: 'Created at',
    payment: 'Payment',
    lease_from: 'Lease from',
    lease_to: 'Lease to',
    building: 'Building',
    locker_number: 'Locker number',
    locker_payment_history: 'Locker payment history',
    parking_payment_history: 'Parking payment history',
    resend_invoice: 'Resend invoice',
    resend_receipt: 'Resend receipt',
    resend_invoice_success: 'The invoice was sent',
  },
  parking_subscriptions_products_list: {
    parking_id: 'Parking ID',
    parking_name: 'Parking Name',
    product_duration: 'Product Duration',
    product_name: 'Product Name',
    product_id: 'Product ID',
    product_price: 'Product Price',
    product_valid_from: 'Product available from',
    product_valid_to: 'Product available to',
    active: 'Active',
    max_spaces_to_sell: 'Spaces to sell',
    seller_company_name: 'Seller company name',
    product_regulations: 'Product regulations',
    subscriptions_list: 'Subscriptions list',
  },
  parking_subscription_form: {
    add_title: 'Add Subscriptions',
    edit_title: 'Edit Subscriptions',
    name_and_price: 'Name and price',
    valid_from: 'Product available from:',
    duration: 'Subscription duration:',
    price: 'Subscription price:',
    price_label: 'Price',
    limit: 'Number of parking spaces for sale:',
    max_advance:
      'Maximum number of days in which a ticket can be purchased before use:',
    min_advance:
      'Minimum number of days in which a ticket can be purchased before use:',
    after_end:
      'How many days after the ticket expires, the parking space will be reserved:',
    valid_to: 'Product available until:',
    active: 'Is the product active:',
    select_parking: 'Select Parking',
    select_parking_no: 'No parking lots',
    rules: 'Regulations',
    select_no_rules: 'No Regulations',
    select_rules: 'Choose a regulations',
    company: 'A company that sells a subscription',
    select_company: 'Choose a company',
    select_company_no: 'No companies',
    add: 'Add',
    active_yes: 'Yes, the product is active',
    active_no: 'No, the product is not active',
    date: 'Choose a date',
    subscription: 'Subscription Name:',
    subscription_pl: 'Polish subscription name',
    subscription_en: 'English subscription name',
    days: 'Days',
    places: 'Lot',
    show_regulation: 'Show regulation content',
    min_advance_others:
      'Minimum number of working days in which a ticket can be purchased before use for buildings other than mine',
    sort: 'Sort',
    amano_product_no: 'Amano product number',
    value: 'Value',
    number: 'Number',
    currency: 'Currency',
    amano_sell_card: 'Amano sell card:',
    car: 'Car',
    motorcycle: 'Motorcycle',
    bike: 'Bike',
    vehicle_type: 'Vehicle type:',
    select_vehicle_type: 'Select vehicle type',
  },
  parking_list: {
    parking_list: 'Parking list',
    edition_parking: 'Edit Parking',
    name: 'Parking Name',
    limit_place: 'Parking space limit',
    places: 'Places',
    building: 'List of assigned buildings to the parking lot:',
    building_list: 'Expand the list of buildings',
    building_list_no: 'No list of buildings',
    parking_places: 'List of dedicated parking spaces',
    parking_places_select: 'Expand the list of targeted locations',
    parking_places_select_no: 'There is no list of specific places',
    remarks: 'Remarks',
    is_dedicate_places: 'There is dedicate place?',
    dedicate_places_number: 'Number of dedicated places',
    parking_building: 'Buildings assigned to the car park',
    company: 'Car park operator',
    company_email: 'Email to car park operator',
    go_to_places: 'Go to parking places',
    date: 'Date',
    capacity: 'Capacity',
    sold: 'Sold',
    used: 'Used',
    parking_occupancy_report: 'Parking occupancy report',
    rented: 'Rented',
    available_for_rent: 'Available for rent in the app',
  },
  sold_parking_items: {
    valid_subscriptions_list: 'Valid subscriptions list',
    sold_one_time_tickets: 'Sold one time parking ticket list',
    valid_from: 'Valid from',
    valid_to: 'Valid to',
    product_name: 'Product name',
    product_id: 'Product Id',
    car_plates: 'Car plates',
    car_plate: 'Car plate',
    status: 'Subscription status',
    ticket_status: 'Status biletu',
    purchase_date: 'Purchase date',
    payment_amount: 'Payment amount',
    payment_status: 'Payment status',
    payment_id: 'OBC payment ID',
    payment_external_id: 'PayU payment ID',
    payment_created_at: 'Payment date',
    logs: 'Logs:',
    no_logs: 'No logs',
    log_message: 'Message',
    created_at: 'Created at',
    valid_to_info: 'with valid to date equal or greater than:',
    details_title: 'Subscription details',
    user: 'Buyer data',
    dates: 'Dates',
    related_tickets_and_payments: 'Related tickets and payments:',
    ticket: 'Ticket',
    place: 'Place',
    parameters: 'Parameters:',
    name: 'name',
    type: 'type',
    value: 'value',
    sync_with_amano: 'Sync with Amano',
    cancel_subscription: 'Cancel subscripion',
    sync_date_and_time: 'Sync date and time',
    subscription_cancelled: 'Subscription cancelled',
    cancel_subscription_info:
      'Are you sure you want to cancel this subscription?',
    ticket_number: 'Ticket number',
    ticket_details: 'Ticket details',
    comment: 'Comment',
    action_comment: 'Cancellation/resynchronization comment',
    reschedule_success: 'Subscription have been synchronized',
    sale_id: 'Sale ID',
    tenant: 'Tenant',
    payment_value: 'Price',
    duration: 'Duration days',
    current_reservations_list: 'Tenant parking lots - current reservations',
    sync_hint: 'You can only synchronise subscriptions integrated into Amano',
    assigned_car_plates: 'Assigned car plates',
  },
  parking_subscription_status: {
    active: 'Active',
    cancelled: 'Cancelled',
    error: 'Error',
    new: 'New',
    past: 'Past',
    pending: 'Pending',
  },
  parking: {
    controller: 'Controller',
    car_plate: 'Car plate',
    ticket: 'Ticket',
  },
  survey: {
    edit_survey: 'Edit survey',
    add_survey: 'Add survey',
    status: 'Survey status',
    resident_only_title: 'Is survey visible to Residents?',
    resident_only: 'Survey is only visible to residents',
    for_all: 'Survey is visible to all',
    survey_name: 'Survey name',
    survey_id: 'Survey Id',
    created_at: 'Created at',
    responses: 'Responses',
    no_answers: 'No answers',
    full_name: 'Full name',
    company: 'Company',
    results_chart: 'Results chart',
    no_options: 'No options',
    show_answers_to_question: 'Show answers to question:',
    preview: 'Preview',
  },
  survey_form: {
    name: 'Name',
    name_en: 'Name EN',
    welcome_text: 'Welcome text',
    welcome_text_en: 'Welcome text EN',
    banner: 'Banner url',
    banner_en: 'Banner url EN',
    question_content: 'Question content',
    question_content_en: 'Question content EN',
    question_type: 'Question type',
    radiobutton: 'Single choice (radio buttons)',
    checkbox: 'Multiple choice (checkboxes)',
    input: 'Short answer (input)',
    textarea: 'Long answer (textarea)',
    add_reply: 'Add option',
    other: 'Other',
    add_question: 'Add question',
    thanks_text: 'Thanks text',
    thanks_text_en: 'Thanks text EN',
    active: 'Active',
    inactive: 'Inactive',
    survey_duration: 'Survey duration',
    no_options: 'No options',
    option: 'Reply',
    option_en: 'Reply EN',
    valid_from: 'Valid from',
    valid_to: 'Valid to',
    success: 'Survey has been saved',
  },
  feature: {
    gastro_mobile: 'Restaurant mobile',
    gastro_admin: 'Restaurant admin',
    parking_mobile: 'Parking mobile',
    parking_admin: 'Parking admin',
    olivia_news: 'Olivia news',
    survey_with_upsert: 'Survey with upsert',
  },
  olivka: {
    blocking_olivka_offers: 'Blocking Olivka offers',
    keyword: 'Keyword',
    add_line: 'Add line',
    offers: 'Offers:',
    restaurants: 'Restaurants:',
    promo: 'Promotions:',
    regex_saved: 'Regex has been saved',
    regex_removed: 'Regex has been removed',
    regex_updated: 'Regex has been edited',
  },
  lockers: {
    title: 'Bike lockers',
    all: 'All',
    ready_to_rent: 'For release',
    rented_overdue: 'To pass',
    rented: 'Rented',
    available: 'Available',
    broken: 'Damaged',
    reserved: 'Reserved',
    cancelled: 'Cancelled',
    error: 'Error',
    rent_locker_manually: 'Rent locker manually',
    rent_lockers_manually: 'Rent lockers manually',
    building: 'Building',
    locker_type: 'Locker type',
    locker_number: 'Locker number',
    key_number: 'Key number',
    transponder_number: 'Transponder number',
    lease_term: 'Lease term',
    tenant: 'Tenant',
    rent_manually: 'Rent manually',
    print_protocol: 'Print protocol',
    save_and_print: 'Save and print',
    print_transfer_protocol: 'Print transfer protocol',
    print_pickup_protocol: 'Print pick-up protocol',
    return_protocol: 'Pick-up protocol',
    bike_locker_details: 'Bike locker details',
    company: 'Company',
    notes: 'Comments',
    logs: 'Locker history (logs)',
    garage: 'Garage locker',
    locker_room: 'Checkroom locker',
    locker_room_with_shower: 'Locker room with showers',
    from: 'From',
    to: 'To',
    small_from: 'from',
    small_to: 'to',
    prereserved: 'Pre-reserved',
    rental: 'Rental',
    reservation: 'Reservation',
    available_lockers: 'Available lockers',
    type: 'Type',
    total: 'Total lockers:',
    rent_confirmation: 'Rent confirmation',
    reservation_confirmation: 'Reservation confirmation',
    company_name: 'Company name',
    company_address: 'Company address',
    city: 'City',
    zip_code: 'Zip code',
    representative: 'Representative',
    netto_amount: 'Netto amount',
    submit_range: 'Submit date range',
    no_results: 'No results',
    rent_protocol: 'Locker rental protocol',
    transfer_protocol: 'Locker handover protocol',
    pickup_protocol: 'Locker pick-up protocol',
    no_lockers_requested: 'Choose number of lockers',
    numbers: 'numbers',
    tenant_tip: 'You can choose from the list or type below',
    edit_reservation: 'Edit reservation',
    close_reservation: 'Close reservation',
    title_annotation: 'Annotation to title',
    locker_status: 'Locker status',
    current_lease: 'Current lease',
    edit_locker: 'Edit locker',
    reservation_status: 'Reservation status',
    bike_locker_reservations: 'Bike locker reservations',
    user: 'User',
    valid_valid_from: 'Date cannot be earlier',
    valid_valid_to: 'Date cannot be later',
    reservation_details: 'Reservation details',
    protocol_button_hint: 'Select building and lockers from the list',
    go_to_transfer_protocol: 'Go to transfer protocol',
    go_to_return_protocol: 'Go to return protocol',
    go_to_edit_locker: 'Go to edit locker',
    selected_lockers: 'Selected lockers',
    rent_selected_lockers_tip:
      'If you want to rent selected lockers, select a building from the filter above the table, then lockers from the list',
    rental_statistics: 'Rental statistics',
    app_rental: 'Rented in app',
    admin_rental: 'Rented outside the app',
    service: 'Service',
    total_table: 'Total',
    change_status: 'Change status',
    change_status_button_hint: 'Select status tab and lockers from the list',
    comment: 'Comment',
    new_status: 'New status',
    valid_status: 'New status must be different from the previous one',
    change_status_info:
      'You can only change the AVAILABLE status to SERVICE or BROKEN.',
    extension: 'Extension',
    cancel_reservation: 'Cancel reservation',
    cancel_reservation_confirmation:
      'Are you sure you want to cancel the reservation of the user: ',
    lease_from: 'Lease from',
    lease_to: 'Lease to',
  },
  locker_status: {
    all: 'All',
    available: 'Available',
    unavailable: 'Unavailable',
    broken: 'Broken',
    error: 'Error',
    rented: 'Rented',
    ready_to_rent: 'To rent',
    should_be_returned: 'To pass',
    service: 'Service',
  },
  locker_reservation_status: {
    reservation_status: 'Reservation status',
    rented: 'Rented',
    available: 'No reservation',
    broken: 'Broken',
    error: 'Error',
    reserved: 'Reserved',
    ready_to_rent: 'To rent',
    should_be_returned: 'To pass',
    service: 'Service',
  },
  locker_products: {
    title: 'Products list',
    product_name: 'Product name',
    building: 'Building',
    period: 'Period',
    days_number: 'Number of days',
    availability: 'Availability',
    price_name: 'Price name',
    price: 'Price',
    garage: 'Garage hall locker with no access to showers',
    locker_room: 'Locker in locker room with showers',
    locker_room_with_shower: 'Garage hall locker with access to showers',
    available: 'Available',
    unavailable: 'Unavailable',
    product_details: 'Product details',
    product_name_en: 'English product name',
    min_advance_days:
      'Minimum number of days in which a locker can be purchased before use:',
    max_advance_days:
      'Maximum number of days in which a locker can be purchased before use:',
    days: 'Days',
    price_name_en: 'English price name',
    seller: 'Company selling the locker',
    logs: 'Logs',
    with_shower: 'With shower',
  },
  locker_group_reservation: {
    group_reservations: 'Group reservations',
    building: 'Building',
    garage_lockers: 'Garage lockers',
    room_lockers: 'Locker room lockers',
    period: 'Period',
    tenant: 'Tenant',
    price: 'Price',
    created_at: 'Created at',
    reservation_details: 'Reservation details',
    edit_reservation: 'Edit reservation',
    cancel_reservation: 'Cancel reservation',
    cancel_reservation_info:
      'Are you sure you want to cancel this reservation?',
    reservation_cancelled: 'Reservation cancelled',
    cancellation_comment: 'Cancellation comment',
    comment: 'Comment',
    reservation_comment: 'Reservation comment',
    cancelled: 'Cancelled',
  },
  logs: {
    logs: 'Logs:',
    no_logs: 'No logs',
    parameters: 'Parameters:',
    name: 'name',
    type: 'type',
    value: 'value',
    show_logs: 'Show logs',
    hide_logs: 'Hide logs',
    created_by: 'created by',
    comment: 'comment',
  },
  parking_places: {
    parking_place_statuses: 'Parking places statuses',
    parking_place_reservations: 'Parking places reservations',
    level: 'Level',
    place: 'Place',
    shared: 'Shared',
    lease_term: 'Lease term',
    company: 'Company',
    tenant: 'Tenant',
    from: 'From',
    to: 'To',
    show_place_numbers: 'Show place numbers',
    available: 'Available',
    unavailable: 'Unavailable',
    reserved: 'Reserved',
    occupied: 'Occupied',
    to_rent: 'To rent',
    rented: 'Rented',
    yes: 'Yes',
    no: 'No',
    hide_numbers: 'Hide place numbers',
    show_numbers: 'Show place numbers',
    unlimited: 'unlimited',
    change_places_status: 'Change parking spaces status',
    change_status: 'Change status',
    new_status: 'New status',
    comment: 'Comment',
    change_status_button_hint: 'Select status and places from the list',
    assign_place_to_parking_pool: 'Assign places to parking pool',
    taken_places: 'Taken places',
    tenant_parking_places: 'Tenant parking places',
    select_places_hint: 'Select tenant and places from the list',
    unassign_places: 'Remove place assignment to tenant',
    confirm_modal_content:
      'Are you sure you want to remove the tenant assignment of selected places?',
    select_tenant: 'Select the tenant to assign spaces to',
    selected_places: 'Selected places',
  },
  parking_pool: {
    add_parking_pool_success: 'Parking pool has been assigned to the company',
    no_valid: 'No assigned parking spaces',
    reservation_notify:
      'Notification to the user about an upcoming reservation',
    notify_office_manager:
      'Notify Office Managers about a completed reservation',
    custom_email_message: 'Custom email message',
    reservation_nearing_in_hours:
      'The number of hours in advance a notification should be sent before a reservation.',
    edit_profile: 'Edit profile',
    do_it_now: 'I will do it now',
    do_it_later: 'I will do it later',
    add_places_hint:
      'To complete the creation of the shared parking spaces product for tenant {{tenant}}, you need to assign spaces to it in the selected parking {{parking}}, in the parking spaces section',
    go_to_places: 'Go to spaces',
    assign_places_hint: `Select spaces from the list, then assign them to the tenant's parking pool`,
  },
  reservation_status: {
    ready_to_rent: 'For release',
    rented_overdue: 'To pass',
    rented: 'Rented',
    available: 'Available',
    broken: 'Damaged',
    reserved: 'Reserved',
    prereserved: 'Pre-reserved',
    error: 'Error',
    cancelled: 'Cancelled',
    finished: 'Finished',
  },
  payment_status: {
    completed: 'Completed',
    confirmed: 'Confirmed',
    error: 'Error',
    failed: 'Failed',
    new: 'New',
    processing: 'Processing',
    received: 'Received',
    cancelled: 'Cancelled',
    timedout: 'Timeout',
    partially_refunded: 'Partially refunded',
    fully_refunded: 'Fully refunded',
  },
  parking_ticket_status: {
    new: 'New',
    reserved: 'Reserved',
    processing: 'Processing',
    paid: 'Paid',
    cancelled: 'Cancelled',
    unavailable: 'Unavailable',
    error: 'Error',
  },
  protocol: {
    date: 'Date',
    locker_no: 'Locker no.',
    key_no: 'Key no.',
    transponder_no: 'Transponder no.',
    tenant: 'Tenant',
    key_number: 'Key number',
    point_1:
      'The receiver hereby declares that he/she has no comments on the condition of the provided bicycle lockers.',
    point_2:
      'Protocol has been drawn up in two copies, one of which is received by Receiver and the other by Transferor.',
    providing: 'Transferor:',
    receiver: 'Receiver:',
    key_to_garrage_locker:
      'a key to the bicycle locker in the garage of the building',
    keys_to_garrage_lockers:
      'keys to the bicycle lockers in the garage of the building',
    keys_to_building_lockers: 'keys to the bicycle lockers in the building',
    key_to_locker_room_locker:
      'keys to the lockers in the locker room of the building',
    keys_to_locker_room_locker:
      'key to the locker in the locker room of the building',
    receiving: 'Receiving Protocol',
  },
  getresponse: {
    title: 'Synchronization with GetResponse',
    sync_date: 'Synchronization date',
    processed_records: 'Processed Records',
    added_records: 'Added Records',
    deleted_records: 'Deleted Records',
    modified_records: 'Modified Records',
    errors: 'Errors',
    sync_progress: 'Synchronization progress:',
    sync: 'Synchronize',
    get_user_list: 'Download user list:',
    permission_to_email: 'who have agreed to be contacted by e-mail:',
    with_garden_tickets: 'with Olivia Garden tickets:',
    pending: 'Pending',
    processing: 'Processing',
    completed: 'Completed',
    canceled: 'Canceled',
    error: 'Error',
    cancel_sync: 'Cancel synchronization',
  },
  events: {
    title: 'List of tickets in the app',
    payment_date: 'Payment date',
    ticket_number: 'Ticket number',
    resident_tickets: 'Resident tickets',
    non_resident_tickets: 'Nonresident tickets',
    event_date: 'Event date',
    event_name: 'Event name',
    user: 'User',
    purchase_location: 'Purchase location',
    price: 'Price',
    payment_status: 'Payment status',
    sales_system: 'Sales system',
    order_id: 'Order ID',
    created_at: 'Creation date',
    reports: 'Reports',
    event_order_report: 'Event order reports',
    tenant: 'Company',
  },
  push: {
    push_notification: 'PUSH notification',
    title: 'Title',
    message: 'Message',
    target: 'Choose who the message should be addressed to:',
    all: 'All',
    residents: 'Residents',
    non_residents: 'Non residents',
    logins: 'Login list',
    tokens: 'FCM token list',
    send_push: 'Send push',
    push_success: 'Push notification has been sent',
    push_confirmation: 'Are you sure you want to send a push notification?',
    developers: 'Developers',
    testers: 'Testers',
    employees: 'Company employees',
    marketing_message: 'Marketing message',
    createdAt: 'Created at',
    body: 'Content',
    author: 'Author',
    push_list: 'Push list',
    to_me: 'To me',
    login: 'To me',
    main_screen: 'Main screen',
    my_account: 'My account',
    my_account_contact_us: 'Contact us',
    garden: 'Olivia Garden',
    surveys: 'Surveys',
    news: 'Olivia News',
    observation_deck: 'Observation deck',
    events: 'Events',
    lockers: 'Lockers',
    hid: 'Access card',
    mobile_view_target_info:
      '(functionality for application version 2.0.2 or later)',
    mobile_view_target_title:
      'The view of the application to which the push is to be directed:',
    mobile_view_target: 'Mobile view target',
    select_language: `Select the recipient's declared language`,
    select_language_tooltip:
      'If a language is selected, the message will be sent only to users who have declared the selected language. When they have not declared, we assume the Polish language.',
  },
  payu: {
    payu_payout: 'PayU payout',
    select_company: 'Select company',
    amount: 'Payout amount',
    description: 'Comment',
    payout: 'Payout',
    balance: 'Balance',
    amount_validation: 'Amount higher than account balance',
    payout_success: 'The payout was successful',
  },
  tenant_access_account: {
    hid_account: 'HID account',
    card_limit: 'Card limit',
    edit_limit: 'Edit limit',
    set_limit: 'Set limit',
    delete_limit: 'Delete limit',
    delete_confirmation: `Are they sure you want to remove this tenant's card limit?`,
  },
  parking_pool_occupancy_report: {
    date: 'Date',
    capacity: 'Active user count',
    sold: 'Reserved',
    used: 'Used',
    max_user_count: 'Max user count',
    max_places_count: 'Places count by contract',
    places_count: 'Places count',
  },
  banner: {
    title: 'Banner in app',
    add_banner: 'Add banner',
    name: 'Name',
    target: 'Users',
    residents: 'Residents',
    all: 'All',
    only_residents: 'Banner visible only to residents:',
    src: 'Banner url',
    src_en: 'Banner EN url',
    url: 'Link',
    url_en: 'Link EN',
    internal: 'Link inside the app',
    external: 'External link',
    mobile_view_target:
      'Select the screen in the application to which the link should go',
    external_link: 'External link to which the banner should direct',
    active: 'Active',
    inactive: 'Inactive',
    edit_banner: 'Edit banner',
    preview: 'Preview',
    preview_hint:
      'Banner preview for the role "banner_preview" will be available when no banner is currently active.',
  },
  skd: {
    access_confirmation_requests: 'Access confirmation requests',
    access_confirmation_request: 'Access confirmation request',
    new: 'New',
    closed: 'Closed',
    message: 'Message:',
    date: 'Date',
    comment: 'Comment',
    accepted: 'Accepted',
    rejected: 'Rejected',
    access_card_list: 'Access card list',
    card_number: 'Card number',
    access_granted: 'Access granted',
    access_account_list: 'Access account list',
    access_cards: 'Access cards',
    device: 'Device',
    delete_confirmation:
      'Are you sure you want to remove the card with the number ',
    access_account_details: 'Access account details',
    company: 'Company',
    privilege_requested: 'Privilege requested',
    privilege_granted: 'Privilege granted',
    no_cards: 'No cards',
    delete_account: 'Delete account',
    delete_account_confirmation:
      'Are you sure you want to delete the mobile access account of the user ',
  },
  extras: {
    event_promo: 'Event promotion',
    promos: 'Olivia Extras',
    active: 'Active',
    inactive: 'Inactive',
    order: 'Order',
    valid_from: 'Valid from',
    valid_to: 'Valid to',
    dates: 'Dates',
    visibility: 'Active',
    edit_promo: 'Edit extras',
    company_name: 'Company/Supplier extras',
    company_logo: 'Company logo - url',
    title: 'Title',
    title_en: 'Title EN',
    description: 'Extras description',
    description_en: 'Extras description EN',
    instruction: 'How to benefit? PL',
    instruction_en: 'How to benefit? EN',
    location: 'Location',
    location_en: 'Location EN',
    choose_event: 'Choose an event',
    choose_related_event: 'Choose an event related to extras',
    event_promotion: 'Event extras',
    fixed_discount: 'Fixed discount',
    choose_dates: 'Select availability dates for extras:',
    choose_logo: 'Select image from list',
    choose_promotion_logo:
      'Select a logo/graphic from the list or provide a URL:',
    add_promo: 'Add extras',
    sort: 'Position',
    set_order: 'Set order',
    discount: 'Discount (e.g. 10% or 5 zł)',
    fixed_type: 'Fixed extras',
    temporary_type: 'Temporary extras',
    event_type: 'Temporary extras - event',
    select_extras_type: 'Select extras type:',
    time_validation: 'You need to choose a date',
    image_url: 'Image URL',
    custom_url: 'Custom URL',
    chosen_url: 'Current image URL:',
  },
  confirm_email_sent_screen: {
    header: 'We have sent the activation link to:',
    info: 'Open the link we sent you to your e-mail address to log in to the application.',
    info2:
      'Mail did not come? Check the SPAM folder. You can resend the link if necessary.',
  },
}

export default en
