import SurveyResultsChart from '../../components/SurveyDetails/SurveyResultsChart'
import { TabsStyled } from '../../components/Styles/CustomElementsStyled'
import React, { useState } from 'react'
import styled from 'styled-components'
import { Typography, Tab } from '@mui/material'
import SurveyAnswersTable from '../../components/SurveyDetails/SurveyAnswersTable'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { GET_SURVEY, IGetSurveyData } from '../../graphql/survey/getSurvey'
import FullScreenLoader from '../../components/Shared/FullScreenLoader'
import { useTranslation } from 'react-i18next'

const SurveyDetails = () => {
  const { t } = useTranslation(['survey'])
  const [value, setValue] = useState(0)
  const surveyId = useParams() as { surveyId: string }

  const chartTypes = ['radiobutton', 'checkbox']

  const countSurveyResponses = (array: string[]) => [...new Set(array)].length

  const { data: getSurvey, loading } = useQuery<IGetSurveyData>(GET_SURVEY, {
    variables: {
      id: surveyId.surveyId,
    },
    fetchPolicy: 'no-cache',
  })

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }

  if (!surveyId || loading || !getSurvey) {
    return <FullScreenLoader />
  }

  const { surveyName, answers } = getSurvey?.getSurvey

  return (
    <>
      <Row>
        <TypographyStyled variant="h6">
          {surveyName || t('survey_name')}
        </TypographyStyled>
        <AnswerCount>
          <b>{countSurveyResponses(answers.map(({ user }) => user.login))}</b>
          {`  ${t('responses')}`}
        </AnswerCount>
      </Row>

      <TabsStyled value={value} onChange={handleChange} textColor="primary">
        <Tab label={t('responses')} />
        {getSurvey.getSurvey.questions.find(({ type }) =>
          chartTypes.includes(type)
        ) && <Tab label={t('results_chart')} />}
      </TabsStyled>

      {value === 0 && <SurveyAnswersTable surveyId={surveyId.surveyId} />}
      {value === 1 && <SurveyResultsChart surveyId={surveyId.surveyId} />}
    </>
  )
}

export default SurveyDetails

const TypographyStyled = styled(Typography)`
  font-weight: 600;
  padding: 6px 0 16px;
  display: inline-block;
  text-align: center;
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
`
const AnswerCount = styled.p`
  display: inline-block;
  padding: 10px 0 16px 16px;
  margin: 0;
`
