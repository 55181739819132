import { useState, useRef } from 'react'
import { useForm, useFormState } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { TextBody2Medium } from '../Styles/TextCustom'
import styled from 'styled-components'
import SummaryTenantDataForm from './SummaryTenantDataForm'
import { BaseButton } from '../Shared/BaseButton'
import { useReactToPrint } from 'react-to-print'
import Protocol from './Protocol'
import { useQuery, useMutation, ApolloError } from '@apollo/client'
import {
  IGetLockerGroupReservationData,
  GET_LOCKER_GROUP_RESERVATION,
} from '../../graphql/lockers/getLockerGroupReservation'
import FullScreenLoader from '../Shared/FullScreenLoader'
import GroupReservationDetails from '../LockerGroupReservations/GroupReservationProtocolDetails'
import { ReservationStatus } from '../../graphql/shared/sharedTypes'
import {
  UPDATE_LOCKER_GROUP_RESERVATION,
  IUpdateLockerGroupReservationVars,
} from '../../graphql/lockers/updateLockerGroupReservation'
import { toast } from 'react-toastify'
import ErrorContainer from '../Shared/ErrorContainer'

export type FormData = {
  name: string
  nip: string
  street: string
  zipcode: string
  city: string
  representative: string
  annotation?: string
}

const GroupReservationSummary = ({
  reservationId,
  pickUp,
}: {
  reservationId: string
  pickUp?: boolean
}) => {
  const { t } = useTranslation(['lockers'])
  const [errorMessage, setErrorMessage] = useState('')
  const [protocolData, setProtocolData] = useState<FormData>()
  const successNotify = (message: string) => toast.dark(message)
  const contentRef = useRef(null)

  const handlePrint = useReactToPrint({
    contentRef,
  })

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<FormData>()
  const { touchedFields } = useFormState({ control })

  const { data, loading } = useQuery<IGetLockerGroupReservationData>(
    GET_LOCKER_GROUP_RESERVATION,
    {
      fetchPolicy: 'no-cache',
      variables: { id: reservationId },
    },
  )

  const onCompleted = () => {
    successNotify(`${t('generic:success_message')}`)
  }

  const [updateLockerGroupReservation] =
    useMutation<IUpdateLockerGroupReservationVars>(
      UPDATE_LOCKER_GROUP_RESERVATION,
      {
        onCompleted,
        onError: (error: ApolloError) => setErrorMessage(error.message),
      },
    )

  if (loading || !data) {
    return <FullScreenLoader />
  }
  const { id, building, lockers, tenant, tenantName, status, buyer } =
    data.groupReservation
  const rental = status === ReservationStatus.rented
  const defaultTenant = tenant?.name ? tenant?.name : tenantName

  const renderProtocol = (data: FormData) => {
    return (
      <Protocol
        ref={contentRef}
        tenantData={data}
        reservations={reservations}
        building={building}
        pickUp={pickUp}
      />
    )
  }

  const handleUpdateGroupReservation = (data: FormData) => {
    const { name, nip, street, city, zipcode, representative } = data
    updateLockerGroupReservation({
      variables: {
        id,
        representative,
        buyer: {
          name,
          nip,
          street,
          city,
          zipcode,
        },
      },
    })
  }

  const onPrint = async (data: FormData) => {
    if (!pickUp && !!Object.keys(touchedFields).length) {
      handleUpdateGroupReservation(data)
    }
    await setProtocolData(data)
    handlePrint()
  }
  const onSave = (data: FormData) => handleUpdateGroupReservation(data)

  const reservations = lockers.map(({ name, key, transponder, type }) => ({
    name,
    key,
    transponder,
    type,
  }))

  return (
    <>
      <TextWithPadding>
        {rental ? t('rental') : t('reservation')}:
      </TextWithPadding>
      <GroupReservationDetails reservation={data.groupReservation} />
      <FormStyled>
        <SummaryTenantDataForm
          errors={errors}
          control={control}
          tenant={defaultTenant}
          buyer={buyer}
          representative={data.groupReservation.representative}
          pickUp={pickUp}
        />

        <ButtonsWrapper container justifyContent={'center'}>
          <Col item xs={6}>
            {!pickUp && (
              <BaseButton onClick={handleSubmit(onSave)} data-e2e="save-button">
                {t('btn:save')}
              </BaseButton>
            )}
          </Col>
          <Col item xs={6}>
            {rental && (
              <BaseButton
                onClick={handleSubmit(onPrint)}
                data-e2e="print-protocol"
              >
                {pickUp ? t('print_protocol') : t('save_and_print')}
              </BaseButton>
            )}
          </Col>
        </ButtonsWrapper>
        {errorMessage && <ErrorContainer errorMessage={errorMessage} />}
      </FormStyled>
      {protocolData && <Wrapper>{renderProtocol(protocolData)}</Wrapper>}
    </>
  )
}

export default GroupReservationSummary
const TextWithPadding = styled(TextBody2Medium)`
  padding: 16px 0;
`
const FormStyled = styled.form`
  padding: 16px 0 0;
`
const Wrapper = styled.div`
  display: none;
`
const Col = styled(Grid)`
  padding: 0 8px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`
const ButtonsWrapper = styled(Grid)`
  padding-top: 30px;
`
