import { Grid, Chip, Stack, Button } from '@mui/material'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { themeColors } from '../../const/colors'
import { InputAdornment } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { BaseTextButton } from '../Shared/BaseButton'
import { useForm } from 'react-hook-form'
import Input from '../Shared/Input'
import { TextBody1Bold } from '../Styles/TextCustom'
import { UserFilter } from '../../screens/Users/UserListScreen'
import UserFilterSelect from './UserFilterSelect'
import UserFilterTenantSelect from './UserFilterTenantSelect'

type FormData = {
  value: string
  tenant: {
    value: string
    label: string
  }
}

const UserFilters = ({
  setCurrentPage,
  filterKey,
  setFilterKey,
  filterValue,
  setFilterValue,
}: {
  setCurrentPage: (v: number) => void
  filterKey: UserFilter | ''
  setFilterKey: (v: UserFilter | '') => void
  filterValue: string
  setFilterValue: (v: string) => void
}) => {
  const { t } = useTranslation(['user_search'])
  const tenantFilter = filterKey === UserFilter.tenant
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>()

  const handleClearFilters = () => {
    setFilterKey('')
    setFilterValue('')
    reset()
  }

  const onSubmit = async (data: FormData) => {
    const { value, tenant } = data

    if (tenantFilter) {
      setFilterValue(tenant.value.trim())
    } else {
      setFilterValue(value.trim())
    }
    await setCurrentPage(0)
  }

  return (
    <Wrapper>
      <TextBody1Bold color={themeColors.primary}>
        {t('filter_title')}
      </TextBody1Bold>
      <Row>
        <UserFilterSelect value={filterKey} setValue={setFilterKey} />
        <Container>
          {!tenantFilter && (
            <Input
              label={t('btn:search')}
              name="value"
              control={control}
              errors={errors}
              rules={{ required: `${t('form:field_required')}` }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon
                      icon={faSearch as IconProp}
                      size="lg"
                      color={themeColors.secondary}
                    />
                  </InputAdornment>
                ),
              }}
              disabled={!filterKey}
            />
          )}
          {tenantFilter && (
            <UserFilterTenantSelect control={control} errors={errors} />
          )}
        </Container>
        <Container>
          <BaseTextButton
            onClick={handleSubmit(onSubmit)}
            disabled={!filterKey}
          >
            {t('btn:submit_filters')}
          </BaseTextButton>
        </Container>
      </Row>
      <Stack direction="row" spacing={1}>
        {filterValue && (
          <ChipStyled label={`${t(`${filterKey}`)}: ${filterValue}`} />
        )}
        {filterValue && (
          <ButtonStyled variant="text" onClick={handleClearFilters}>
            {t('btn:clear_filters')}
          </ButtonStyled>
        )}
      </Stack>
    </Wrapper>
  )
}

export default UserFilters

const Wrapper = styled(Grid)`
  background-color: ${themeColors.lightBlue};
  padding: 8px 16px;
  border-radius: 30px;
  margin-bottom: 16px;
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
`
const ChipStyled = styled(Chip)`
  color: ${themeColors.primary};
  background-color: ${themeColors.lightBlue};
  font-size: 14px;
  font-weight: 500;
`
const ButtonStyled = styled(Button)`
  text-transform: none;
  font-weight: 700;
`
const Container = styled.div`
  flex: 1;
  padding: 0 16px;
  max-width: 500px;
`
