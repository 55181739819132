import { useLayoutEffect, useMemo, useState } from 'react'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { themeColors } from '../../const/colors'
import {
  dateIso2ExcelFormat,
  dateIso2localeString,
} from '../../utils/formatDate'
import { AccessCardType } from '../../graphql/users/setAccessCardForUser'
import { useNavigate, useParams } from 'react-router-dom'
import FullWidthDrawer from '../../components/Shared/FullWidthDrawer'
import UserDetails from '../../components/UserDetails/UserDetails'
import { HeaderContainer } from '../../components/Styles/CustomElementsStyled'
import { CircularProgress } from '@mui/material'
import Table from '../../components/Table'
import { ColumnDef } from '@tanstack/react-table'
import UserFilters from '../../components/UserDetails/UserFilters'
import { useFetchFilteredUsers } from '../../hooks/useFetchFilteredUsers'
import { Alert, AlertTitle } from '@mui/material'

export interface Row {
  fullName: string
  login: string
  email: string
  workEmail: string
  tenantName?: string
  lastMobileApp?: string
  lastSeen?: string
  lastSeenCSV?: string
  apiVersion: string
  cardType?: AccessCardType
  cardTypeString?: string
  disabled: string
  roles: string
  features: string
  carPlates: string
  confirmed: string
  createdAt: string
  resident: string
  isSeos: boolean
}
export enum UserFilter {
  login = 'login',
  lastName = 'lastName',
  email = 'email',
  workEmail = 'workEmail',
  tenant = 'tenant',
  carPlate = 'carPlate',
  cardNumber = 'cardNumber',
}

const UserList = () => {
  const { t, i18n } = useTranslation(['columns'])
  const [openedDetails, setOpenedDetails] = useState<string | null>(null)
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(20)
  const [filterKey, setFilterKey] = useState<UserFilter | ''>('')
  const [filterValue, setFilterValue] = useState('')
  const lang = i18n.language
  const navigate = useNavigate()
  const { userId } = useParams()
  const { users, loading, itemsCount, refetch } = useFetchFilteredUsers(
    pageSize,
    currentPage,
    filterKey,
    filterValue,
  )

  const invisibleColumns = {
    lastSeenCSV: false,
    apiVersion: false,
    carPlates: false,
    confirmed: false,
    createdAt: false,
    resident: false,
  }

  const csvHeaders = [
    {
      label: t('full_name'),
      key: 'fullName',
    },
    {
      label: 'Login',
      key: 'login',
    },
    {
      label: 'Email',
      key: 'email',
    },
    {
      label: t('work_email'),
      key: 'workEmail',
    },
    {
      label: t('company'),
      key: 'tenantName',
    },
    {
      label: t('last_mobile_app'),
      key: 'lastMobileApp',
    },
    {
      label: t('last_seen'),
      key: 'lastSeenCSV',
    },
    {
      label: t('api_version'),
      key: 'apiVersion',
    },
    {
      label: t('card_type'),
      key: 'cardType',
    },
    {
      label: t('user_details:lock'),
      key: 'disabled',
    },
    {
      label: t('roles'),
      key: 'roles',
    },
    {
      label: t('features'),
      key: 'features',
    },
    {
      label: t('car_plate'),
      key: 'carPlates',
    },
    {
      label: 'Konto potwierdzone',
      key: 'confirmed',
    },
    {
      label: 'Data założenia konta',
      key: 'createdAt',
    },
    {
      label: 'Rezydent',
      key: 'resident',
    },
  ]

  const columns: ColumnDef<Row, any>[] = useMemo(
    () => [
      {
        accessorKey: 'fullName',
        header: t('full_name'),
        enableSorting: false,
      },
      {
        accessorKey: 'login',
        header: t('login'),
        enableSorting: false,
      },
      {
        accessorKey: 'email',
        header: t('main_email'),
        enableSorting: false,
      },
      {
        accessorKey: 'workEmail',
        header: t('work_email'),
        enableSorting: false,
      },
      {
        accessorKey: 'tenantName',
        header: t('company'),
        enableSorting: false,
      },
      {
        accessorKey: 'lastMobileApp',
        header: t('last_mobile_app'),
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: 'lastSeen',
        header: t('last_seen'),
        cell: (value) => dateIso2localeString(value.getValue(), lang),
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: 'lastSeenCSV',
        header: t('last_seen'),
        enableGlobalFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: 'apiVersion',
        header: t('api_version'),
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: 'cardTypeString',
        header: t('card_type'),
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: 'disabled',
        header: t('user_details:lock'),
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: 'roles',
        header: t('roles'),
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: 'features',
        header: t('features'),
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: 'carPlates',
        header: t('car_plate'),
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: 'confirmed',
        header: t('account_confirmed'),
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: 'createdAt',
        header: t('account_created'),
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: 'resident',
        header: t('resident'),
        enableColumnFilter: false,
        enableSorting: false,
      },
    ],
    [t, lang],
  )

  const incorrectCardType = (
    seos: boolean,
    card: AccessCardType | undefined,
  ) => (seos ? card !== AccessCardType.seos : card === AccessCardType.seos)

  const handleCloseDrawer = () => {
    refetch()
    setOpenedDetails(null)
    userId !== ':userId' &&
      navigate('/admin/user/list', {
        state: {},
        replace: true,
      })
  }

  const tableData: Row[] = useMemo(
    () =>
      users.map(
        ({
          login,
          createdAt,
          firstName,
          lastName,
          email,
          workEmail,
          tenant,
          roles,
          features,
          lastMobileApp,
          lastSeen,
          apiVersion,
          disabled,
          carPlates,
          accessCard,
        }) => {
          const activeRoles = roles.map(({ name }) => name)
          return {
            login,
            fullName: `${firstName} ${lastName}`,
            email: email?.email || '',
            workEmail: workEmail?.email || '',
            tenantName: tenant?.name || '',
            lastMobileApp: lastMobileApp || '',
            lastSeen,
            lastSeenCSV: dateIso2ExcelFormat(lastSeen),
            apiVersion: `${apiVersion}`,
            disabled: disabled ? t('bool:yes') : t('bool:no'),
            roles: activeRoles.join(', '),
            features: features?.map(({ name }) => name).join(', '),
            carPlates: carPlates?.map(({ name }) => name).join(', '),
            confirmed: !!email?.email ? t('bool:yes') : t('bool:no'),
            createdAt: dateIso2ExcelFormat(createdAt),
            resident: !!tenant ? t('bool:yes') : t('bool:no'),
            cardType: accessCard?.type,
            cardTypeString: String(accessCard?.type || ''),
            isSeos: !!tenant?.seos && !tenant.seos.disabled,
          }
        },
      ) || [],
    [users, t],
  )
  useLayoutEffect(() => {
    if (!!userId && userId !== ':userId') {
      tableData.find(({ login }) => login === userId) &&
        setOpenedDetails(userId)
    }
  }, [userId, tableData])

  return (
    <>
      <HeaderContainer>
        <div>
          <RowContainer>
            <TypographyStyled variant="h6">
              {t('admin_menu:users_list')}
            </TypographyStyled>
            {loading && <Progress size={28} />}
          </RowContainer>
          <TypographyUnconfirmed>
            {t('user_details:unconfirmed_user')}
          </TypographyUnconfirmed>
        </div>
      </HeaderContainer>
      <Table
        columns={columns}
        data={tableData}
        manualPagination
        pageSize={pageSize}
        setPageSize={setPageSize}
        count={itemsCount}
        pageIndex={currentPage}
        setCurrentPage={setCurrentPage}
        manualSorting
        globalSearch={false}
        manualFiltering
        enableColumnFilters={false}
        columnVisibility={invisibleColumns}
        enableRowSelection
        onRowClick={(rowData) => {
          setOpenedDetails(rowData.login)
        }}
        csvExport
        csvHeaders={csvHeaders}
        getRowProps={(rowData: any) => ({
          style: {
            background: !rowData?.email
              ? themeColors.lightNegative
              : rowData?.disabled === t('generic:yes') && themeColors.lightGray,
          },
        })}
        getCellProps={(cellInfo) =>
          cellInfo.column.id === 'cardTypeString' &&
          incorrectCardType(
            cellInfo.row.original.isSeos,
            cellInfo.row.original.cardType,
          )
            ? {
                style: {
                  color: themeColors.error,
                  fontWeight: 600,
                },
              }
            : {}
        }
        csvFileName="users-table.csv"
        filterCsvValue={t('bool:yes')}
        filterCsvKey="disabled"
      >
        <UserFilters
          filterKey={filterKey}
          setFilterKey={setFilterKey}
          filterValue={filterValue}
          setFilterValue={setFilterValue}
          setCurrentPage={setCurrentPage}
        />
        <AlertStyled severity="info" color="warning">
          <AlertTitle>Uwaga</AlertTitle>
          {
            'To jest nowa lista użytkowników. Jeśli masz sugestie, daj nam znać!'
          }
        </AlertStyled>
      </Table>
      <FullWidthDrawer open={!!openedDetails}>
        {openedDetails && (
          <UserDetails login={openedDetails} closeDetails={handleCloseDrawer} />
        )}
      </FullWidthDrawer>
    </>
  )
}

const TypographyStyled = styled(Typography)`
  font-weight: 600;
  padding-bottom: 1rem;
`
const TypographyUnconfirmed = styled(Typography)`
  background: ${themeColors.lightNegative};
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  padding: 5px;
  margin-bottom: 16px;
`
const Progress = styled(CircularProgress)`
  color: ${themeColors.primary};
  padding-bottom: 1rem;
  margin-left: 16px;
`
const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
`
const AlertStyled = styled(Alert)`
  margin: 16px 0;
`

export default UserList
