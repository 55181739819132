import { configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import user from './user/reducer'

type RootState = ReturnType<typeof store.getState>
type AppDispatch = typeof store.dispatch

export const store = configureStore({
  // @ts-ignore
  reducer: { user },
})

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
