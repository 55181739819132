import { gql } from '@apollo/client'
import { AccessCardType } from './setAccessCardForUser'

export enum UserAnonymizeStatus {
  empty = 'empty',
  to_anonymize = 'to_anonymize',
  anonymized = 'anonymized',
  error = 'error',
}

export interface IUser {
  login: string
  createdAt: string
  firstName: string
  lastName: string
  email: { email: string } | null
  workEmail: { email: string } | null
  mobilePhone: string
  tenant: {
    name: string
    id: string
    seos?: {
      disabled?: boolean
      id: string
      prefix: string
    }
  } | null
  accessCard: {
    number: string
    type: AccessCardType
  } | null
  roles: { name: string }[]
  features: { name: string; id: string }[]
  lastMobileApp: string
  lastSeen: string
  apiVersion: number
  disabled: boolean
  anonymizedDate: string
  anonymizeStatus: UserAnonymizeStatus
  acceptances: {
    decision: boolean
    regulation: {
      regulationId: string
      title: string
    }
  }[]
  managesRestaurants: {
    id: string
    name: string
  }[]
  carPlates: {
    name: string
  }[]
}

export interface IQueryUsersData {
  queryUser: IUser[]
  aggregateUser: {
    count: number
  }
}

export interface IQueryUserVars {
  first?: number
  offset?: number
  filter?:
    | { [key: string]: { anyofterms: string } }
    | { [key: string]: { eq: string } }
  email?: string
  workEmail?: string
  tenant?: string
  carPlate?: string
  cardNumber?: string
}

export const USER_FRAGMENT = gql`
  fragment UserParts on User {
    login
    createdAt: created_at
    firstName: first_name
    lastName: last_name
    lastMobileApp: last_mobile_app
    lastSeen: last_seen
    apiVersion: api_version
    disabled
    roles {
      name
    }
    features {
      name
    }
  }
`

export const QUERY_USER = gql`
  ${USER_FRAGMENT}
  query queryUser($first: Int, $offset: Int, $filter: UserFilter) {
    queryUser(
      first: $first
      offset: $offset
      order: { asc: last_name }
      filter: $filter
    ) {
      ...UserParts
      email {
        email
      }
      workEmail: work_email {
        email
      }
      tenant {
        name
        id
        seos {
          disabled
          id
          prefix
        }
      }
      carPlates: car_plates {
        name
      }
      accessCard: access_card {
        number
        type
      }
    }
    aggregateUser(filter: $filter) {
      count
    }
  }
`
export const QUERY_USER_WITH_EMAIL = gql`
  ${USER_FRAGMENT}
  query queryUser($first: Int, $offset: Int, $email: String) {
    queryUser(first: $first, offset: $offset, order: { asc: last_name })
      @cascade(fields: ["email"]) {
      ...UserParts
      email(filter: { email: { eq: $email } }) {
        email
      }
      workEmail: work_email {
        email
      }
      tenant {
        name
        id
        seos {
          disabled
          id
          prefix
        }
      }
      carPlates: car_plates {
        name
      }
      accessCard: access_card {
        number
        type
      }
    }
    aggregateUser {
      count
    }
  }
`
export const QUERY_USER_WITH_WORK_EMAIL = gql`
  ${USER_FRAGMENT}
  query queryUser($first: Int, $offset: Int, $workEmail: String) {
    queryUser(first: $first, offset: $offset, order: { asc: last_name })
      @cascade(fields: ["work_email"]) {
      ...UserParts
      email {
        email
      }
      workEmail: work_email(filter: { email: { eq: $workEmail } }) {
        email
      }
      tenant {
        name
        id
        seos {
          disabled
          id
          prefix
        }
      }
      carPlates: car_plates {
        name
      }
      accessCard: access_card {
        number
        type
      }
    }
    aggregateUser {
      count
    }
  }
`

export const QUERY_USER_WITH_TENANT = gql`
  ${USER_FRAGMENT}
  query queryUser($first: Int, $offset: Int, $tenant: String) {
    queryUser(first: $first, offset: $offset) @cascade(fields: ["tenant"]) {
      ...UserParts
      email {
        email
      }
      workEmail: work_email {
        email
      }
      tenant(filter: { slug: { eq: $tenant } }) {
        name
        name_lowercase
        slug
        id
        seos {
          disabled
          id
          prefix
        }
      }
      carPlates: car_plates {
        name
      }
      accessCard: access_card {
        number
        type
      }
    }
    aggregateUser {
      count
    }
  }
`
export const QUERY_USER_WITH_CAR_PLATE = gql`
  ${USER_FRAGMENT}
  query queryUser($first: Int, $offset: Int, $carPlate: String) {
    queryUser(first: $first, offset: $offset) @cascade(fields: ["car_plates"]) {
      ...UserParts
      email {
        email
      }
      workEmail: work_email {
        email
      }
      tenant {
        name
        name_lowercase
        slug
        id
        seos {
          disabled
          id
          prefix
        }
      }
      carPlates: car_plates(filter: { name: { anyofterms: $carPlate } }) {
        name
      }
      accessCard: access_card {
        number
        type
      }
    }
    aggregateUser {
      count
    }
  }
`
export const QUERY_USER_WITH_CARD_NUMBER = gql`
  ${USER_FRAGMENT}
  query queryUser($first: Int, $offset: Int, $cardNumber: String) {
    queryUser(first: $first, offset: $offset)
      @cascade(fields: ["access_card"]) {
      ...UserParts
      email {
        email
      }
      workEmail: work_email {
        email
      }
      tenant {
        name
        name_lowercase
        slug
        id
        seos {
          disabled
          id
          prefix
        }
      }
      carPlates: car_plates {
        name
      }
      accessCard: access_card(filter: { number: { anyofterms: $cardNumber } }) {
        number
        type
      }
    }
    aggregateUser {
      count
    }
  }
`
