import { useMemo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  dateIso2ExcelFormat,
  dateIso2localeString,
  date2isoString,
} from '../../utils/formatDate'
import { Typography } from '@mui/material'
import styled from 'styled-components'
import { useLazyQuery, ApolloError } from '@apollo/client'
import {
  QUERY_TENANT_PARKING_POOL_SUBSCRIPTIONS,
  IQueryTenantParkingPoolSubscriptionsVars,
  IQueryTenantParkingPoolSubscriptionsData,
} from '../../graphql/parking/queryTenantParkingPoolSubscriptions'
import {
  ParkingSubscriptionStatus,
  IParkingTicket,
  ISubscription,
} from '../../graphql/parking/queryParkingSubscriptions'
import {
  GET_TENANT_PARKING_POOL_REPORT,
  IGetTenantParkingPoolReportVars,
  IGetTenantParkingPoolReportData,
} from '../../graphql/parking/queryTenantParkingPoolReport'
import { ILog } from '../../graphql/shared/sharedTypes'
import { useLocation } from 'react-router-dom'
import FullScreenLoader from '../../components/Shared/FullScreenLoader'
import BaseDrawer from '../../components/Shared/BaseDrawer'
import SubscriptionDetails from '../../components/Parking/SubscriptionDetails'
import { getSubscriptionStatusColor } from '../../utils/getStatusColor'
import { formatToExcelPrice } from '../../utils/formatPrice'
import ErrorContainer from '../../components/Shared/ErrorContainer'
import ParkingPoolReservationsFilter from '../../components/Parking/ParkingPoolReservationsFilter'
import Table from '../../components/Table'
import { ColumnDef } from '@tanstack/react-table'

export interface TenantParkingReservationRow {
  id: string
  status: ParkingSubscriptionStatus
  validFrom: string
  validTo: string
  validFromCSV: string
  validToCSV: string
  createdAt: string
  createdAtCSV: string
  carPlate: string
  carPlate2: string
  carPlates: string
  productName: string
  durationDays: number
  login: string
  email: string
  tickets: IParkingTicket[]
  tenant: string
  priceCSV?: string
  parking: string
  amanoCardNo: number
  operator: string
}

const now = new Date(new Date().setHours(0, 0, 0, 0))

const TenantParkingReservations = () => {
  const { t, i18n } = useTranslation(['sold_parking_items'])
  const [minValidTo, setMinValidTo] = useState<Date | null>(now)
  const [openedDetails, setOpenedDetails] =
    useState<TenantParkingReservationRow>()
  const [tenant, setTenant] = useState<string | null>(null)
  const [items, setItems] = useState<ISubscription[]>([])
  const [error, setError] = useState('')
  const location = useLocation()
  const columnFilter: any = location?.state
  const lang = i18n.language

  const days = (date1: Date, date2: Date) => {
    const difference = date1.getTime() - date2.getTime()
    const totalDays = Math.ceil(difference / (1000 * 3600 * 24))
    return totalDays
  }

  const invisibleColumns = {
    id: false,
    validFromCSV: false,
    validToCSV: false,
    createdAtCSV: false,
    priceCSV: false,
    amanoCardNo: false,
  }

  const csvHeaders = [
    {
      label: t('valid_from'),
      key: 'validFromCSV',
    },
    {
      label: t('valid_to'),
      key: 'validToCSV',
    },
    {
      label: t('duration'),
      key: 'durationDays',
    },
    {
      label: t('car_plate'),
      key: 'carPlate',
    },
    {
      label: `${t('car_plate')} 2`,
      key: 'carPlate2',
    },
    {
      label: 'Login',
      key: 'login',
    },
    {
      label: 'Email',
      key: 'email',
    },
    {
      label: t('tenant'),
      key: 'tenant',
    },
    {
      label: 'Status',
      key: 'status',
    },
    {
      label: t('purchase_date'),
      key: 'createdAtCSV',
    },
    {
      label: 'Parking',
      key: 'parking',
    },
    {
      label: `${t('payment_value')} (PLN)`,
      key: 'priceCSV',
    },
    {
      label: 'AMANO numer biletu',
      key: 'amanoCardNo',
    },
  ]

  const [querySubscriptions, { loading, refetch }] = useLazyQuery<
    IQueryTenantParkingPoolSubscriptionsData,
    IQueryTenantParkingPoolSubscriptionsVars
  >(QUERY_TENANT_PARKING_POOL_SUBSCRIPTIONS, {
    fetchPolicy: 'no-cache',
    onCompleted: (data: IQueryTenantParkingPoolSubscriptionsData) =>
      setItems(data.subscriptions),
    onError: (error: ApolloError) => setError(error.message),
  })

  const [getReport, { loading: getReportLoading }] = useLazyQuery<
    IGetTenantParkingPoolReportData,
    IGetTenantParkingPoolReportVars
  >(GET_TENANT_PARKING_POOL_REPORT, {
    fetchPolicy: 'no-cache',
    onCompleted: (data: IGetTenantParkingPoolReportData) =>
      setItems(
        data.queryParkingTicket.map(({ subscription }) => subscription) || []
      ),
    onError: (error: ApolloError) => setError(error.message),
  })

  const columns: ColumnDef<TenantParkingReservationRow, any>[] = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
      },
      {
        accessorKey: 'validFrom',
        header: t('valid_from'),
        cell: (value) => dateIso2localeString(value.getValue(), lang),
        enableColumnFilter: false,
      },
      {
        accessorKey: 'validFromCSV',
        header: t('valid_from'),
      },
      {
        accessorKey: 'validTo',
        header: t('valid_to'),
        cell: (value) => dateIso2localeString(value.getValue(), lang),
        enableColumnFilter: false,
      },
      {
        accessorKey: 'validToCSV',
        header: t('valid_to'),
      },
      {
        accessorKey: 'durationDays',
        header: t('duration'),
      },
      {
        accessorKey: 'carPlate',
        header: t('car_plate'),
      },
      {
        accessorKey: 'carPlate2',
        header: `${t('car_plate')} 2`,
      },
      {
        accessorKey: 'login',
        header: 'Login',
      },
      {
        accessorKey: 'email',
        header: 'Email',
      },
      {
        accessorKey: 'tenant',
        header: t('tenant'),
      },
      {
        accessorKey: 'status',
        header: t('status'),
        cell: (value) => t(`parking_subscription_status:${value.getValue()}`),
      },
      {
        accessorKey: 'createdAt',
        header: t('purchase_date'),
        cell: (value) => dateIso2localeString(value.getValue(), lang),
        enableColumnFilter: false,
      },
      {
        accessorKey: 'createdAtCSV',
        header: t('purchase_date'),
      },
      {
        accessorKey: 'parking',
        header: 'Parking',
      },
      {
        accessorKey: 'priceCSV',
        header: t('payment_value'),
      },
      {
        accessorKey: 'amanoCardNo',
        header: 'AMANO numer biletu',
      },
    ],
    [t, lang]
  )

  const tableData: TenantParkingReservationRow[] = useMemo(() => {
    return (
      items?.map(
        ({
          id,
          status,
          validFrom,
          validTo,
          createdAt,
          parkingPass: { name, nameEn, parking },
          user: { login, email, tenant },
          tickets,
          amanoCardNo,
        }) => ({
          id,
          validFrom,
          validTo,
          validFromCSV: dateIso2ExcelFormat(validFrom),
          validToCSV: dateIso2ExcelFormat(validTo),
          carPlate: tickets[0]?.carPlate || '',
          carPlate2: tickets[0]?.carPlate2 || '',
          carPlates: `${tickets[0]?.carPlate}${
            tickets[0].carPlate2 ? `, ${tickets[0].carPlate2}` : ''
          }`,
          productName: lang === 'pl' ? name : nameEn,
          durationDays: days(new Date(validTo), new Date(validFrom)),
          login,
          email: email?.email,
          status,
          createdAt,
          createdAtCSV: dateIso2ExcelFormat(createdAt),
          tickets,
          tenant: tenant.name,
          parking: parking.name,
          priceCSV: formatToExcelPrice(tickets[0]?.payment?.amount),
          amanoCardNo,
          operator: parking.operator,
        })
      ) || []
    )
  }, [items, lang])

  useEffect(() => {
    if (minValidTo) {
      !tenant
        ? querySubscriptions({
            variables: { minValidTo: date2isoString(minValidTo) },
          })
        : getReport({
            variables: {
              parkingPoolId: tenant,
              minValidTo: date2isoString(minValidTo),
            },
          })
    }
  }, [minValidTo, tenant])

  if (loading || getReportLoading) {
    return <FullScreenLoader />
  }

  return (
    <>
      <Title variant="h6">{t('current_reservations_list')}</Title>
      <ParkingPoolReservationsFilter
        minValidTo={minValidTo}
        setMinValidTo={setMinValidTo}
        tenant={tenant}
        setTenant={setTenant}
      />
      <Table
        columns={columns}
        data={tableData}
        columnVisibility={invisibleColumns}
        enableRowSelection
        csvExport
        csvHeaders={csvHeaders}
        onRowClick={(rowData: TenantParkingReservationRow) => {
          rowData && setOpenedDetails(rowData)
        }}
        getCellProps={({ column, row }) =>
          column.id === 'status'
            ? {
                style: {
                  color: getSubscriptionStatusColor(row.original.status),
                  fontWeight: 600,
                },
              }
            : {}
        }
        sortByKey="validTo"
        sortDesc
      />
      {!!error && <ErrorContainer errorMessage={error} />}
      <BaseDrawer open={!!openedDetails} variant={'temporary'}>
        {!!openedDetails && (
          <SubscriptionDetails
            closeDetails={() => setOpenedDetails(undefined)}
            data={openedDetails}
            refetch={refetch}
          />
        )}
      </BaseDrawer>
    </>
  )
}

export default TenantParkingReservations

const Title = styled(Typography)`
  font-weight: 600;
  padding-bottom: 1rem;
  padding-right: 10px;
`
