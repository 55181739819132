import gql from 'graphql-tag'
import { IPlace } from './queryParkingPlace'

export interface IQueryTenantParkingPlaceData {
  places: IPlace[]
}
export const QUERY_TENANT_PARKING_PLACE = gql`
  query queryParkingPlace {
    places: queryParkingPlace @cascade(fields: ["tenant"]) {
      id
      name
      status
      parking {
        name
      }
      level {
        name
      }
      building {
        name
      }
      tenant {
        id
        name
      }
      assignedTo: assigned_to {
        login
        firstName: first_name
        lastName: last_name
      }
      shared
      reservations(order: { desc: created_at }) {
        validFrom: valid_from
        validTo: valid_to
        createdAt: created_at
        status
      }
    }
  }
`
